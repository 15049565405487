@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Verdana", "Geneva", "Tahoma", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input {
  border: 1px solid;
  border-radius: 5px;
  padding: 0px 3px;
}

.custom-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border-collapse: collapse;
}
.header-cell {
  border-radius: 4px;
}
.header-cell td {
  background-color: #43505f;
  color: white;

  border: 1px solid #055fc5;
}

.table-row {
  height: 15px;
  margin: 5px 0;
  padding: 10px;
  border-bottom: 0.3px solid #002d62; /* Add bottom border between rows */
}

.custom-table td {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding: 4px 16px 4px 40px;
}
.header-cell tr > td {
  color: white;
}
.custom-table > tbody > tr > td {
  background: white;
}
.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
  border-radius: 5px; /* Apply border-radius to the table */
}

.custom-table td,
.custom-table th {
  padding: 5px 10px;
  border-top: 1px solid gray;
}

.custom-table tr:first-child th {
  border-top: none;
}

.custom-table tr:last-child td {
  border-bottom: none;
}
.overflow__y__ {
  overflow-y: auto !important;
}
