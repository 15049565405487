.navbar {
  background-color: #002d62;
  padding: 2px;
  height: 8vh;
}
.navbar-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: Mulish, fira-sans-2, sans-serif;
  font-size: 20px;
}
.Logged_in {
  margin-left: 400px;
}
.logout-button {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-size: 18px;
  height: 30px;
  margin-right: 3px;
  opacity: 1;
  padding: 10px 40px;
  text-decoration: none;
  visibility: visible;
  width: 50px;
}

@media only screen and (max-width: 600px) {
  .Logged_in {
    margin-left: 33px;
  }
  .navbar-list {
    font-size: 10px;
    margin-top: 10px;
  }
  .logout-button {
    height: 27px;
    padding: 10px 36px 13px 41px;
  }
}
