.dashboard {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  width: 500px;
  height: 80px;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #1ecaf9 0,
    #002d62 100%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  transition: transform 0.7s;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05) rotateY(10deg);
}

.card-title {
  font-size: 18px;
  text-align: center;
  padding: 15px;
}

.card-content {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
  .card-title {
    font-size: 16px;
  }
}
.table_1 {
  border-collapse: collapse;
  border-radius: 4px;
  outline: 1.5px solid #585757;
}
.table_1 thead {
  z-index: 0;
}
.button {
  background-color: #002d62;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin: 0px 10px;
  padding: 0px 20px;
  height: 35px;
}
.table_1 thead,
.table_1_ thead {
  background: #002d62;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  height: 50px;
  outline: 1.5px solid #585757;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table_1 tr td {
  border-bottom: 1px solid gray;
  font-size: 12px;
  padding: 10px 13px;
}

.toshow__Print {
  display: none;
}

@media print {
  .hide__when__print {
    display: none !important;
  }
  .toshow__Print {
    display: block;
  }
  .toshow__Print table {
    border: 1px solid;
  }
  .toshow__Print table tr,
  .toshow__Print table td {
    border: 1px solid;
    padding: 5px;
  }
}
