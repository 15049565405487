.list_driver_code {
  gap: 10px;
  margin-left: 0px;
  width: 297px;
}
.list_of_vehi {
  display: flex;
  font-weight: 500;
  gap: 36px;
  align-items: center;
  width: 490px;
}
.list1_of_vehi {
  display: flex;
  font-weight: 500;
  gap: 10px;
  margin-left: -90px;
  align-items: center;
}
.vehi_no {
  gap: 5px;
  margin-left: 125px;
}
.align__date_1 {
  margin: 10px 10px 0px 10px;
  display: flex;

  align-items: center;
  padding: 10px;
  background-color: aliceblue;
  border-radius: 5px;
}
.analytics_supp_select_1 {
  display: flex;
  align-items: center;

  gap: 10px;
  margin-left: -40px;
}
.input_list_start {
  height: 30px;
  width: 130px;
  border-radius: 5px;
  border: 1px solid;
}
.input_list_end {
  height: 30px;
  width: 130px;
  border-radius: 5px;
  border: 1px solid;
}
.list_start {
  display: flex;
  font-weight: 500;
  gap: 20px;
  align-items: center;
  width: 312px;
}
.list1_start {
  display: flex;
  font-weight: 500;
  gap: 40px;
  align-items: center;
  width: 504px;
}
.vehi_cost {
  margin-left: 10px;
  gap: 4px;
}

.select {
  margin-left: 5px;
}
.list_driver_name {
  gap: 8px;
  margin-left: 15px;
}
.trip_end_date {
  margin-left: 18px;
}
.location_no {
  margin-left: 63px;
}
.code_2 {
  margin-left: 32px;
}
.trip_delivery {
  width: 360px;
  margin-left: 20px;
  gap: 25px;
}
.code_1 {
  width: 131px;
}
.vehi_make {
  margin-left: 1px;
  gap: 13px;
}
/* .trip_contract {
  gap: 5px;
  width: 300px;
} */
.trip_route_start {
  gap: 5px;
  margin-left: 0px;
  width: 539px;
}
.trip_route_end {
  gap: 26px;
  margin-left: 0px;
}
.trip_driver_name {
  gap: 41px;
  margin-left: 1px;
}
.trip_client_name {
  gap: 62px;
  margin-left: 23px;
}
.fuel_vehi_no {
  gap: 25px;
  margin-left: 123px;
}
.fuel_driver_code {
  gap: 5px;
  margin-left: 0px;
}
.fuel_driver_name {
  gap: 5px;
  margin-left: 37px;
}
.fuel_contract_no {
  gap: 8px;
  margin-left: 66px;
}
.fuel_make {
  gap: 60px;
}
.fuel_route {
  gap: 54px;
  margin-left: 20px;
}
.fuel_model {
  margin-left: 74px;
  gap: 95px;
}
.header__of__main__dashboard {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.header__of__main__dashboard > div {
  width: 500px;
}
.fuel__dynamic__tab {
  display: flex;
  justify-content: space-around;
  margin: 0px 5px;
}
.fuel__dynamic__tab > button {
  background: rgba(5, 5, 91, 0.676);
  border: none;
  color: white;
  width: 50%;
  margin: 5px 0px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.fuel__dynamic__tab > .active {
  background: rgba(253, 253, 254, 0.676);
  border: 2px solid;
  border-bottom: none;
  color: rgb(10, 0, 0);
  width: 50%;
  margin: 5px 0px;
  padding: 5px;
  border-radius: 5px;
}
.for__styling__pai {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.chart__active__buttons {
  display: flex;
  justify-content: end;
  margin: 0px 5px;
}
.chart__active__buttons > button {
  width: 40px;
  background-color: rgba(88, 86, 86, 0.626);
  border: none;
  margin: 5px;
  font-size: larger;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  display: flex;
  justify-content: center;
}

.chart__active__buttons > .active__chart {
  background: rgba(253, 253, 254, 0.676);
  border: 2px solid grey;

  color: rgb(10, 0, 0);

  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  transition: 0.1s ease-in-out;
}
.flex__pai {
  display: flex;
  justify-content: space-around;
}
.flex__pai > div {
  width: 400px;
}
.for__single__pai {
  width: 50%;
  height: 300px;
}
.select__cunsuption,
.route_select {
  width: 350px;
}
.header__of__page {
  font-weight: bold;
  font-size: 24px;
}
.date__custom {
  border: 1px solid;
  position: absolute;
  top: 180px;
  left: 20px;
  display: block;
  background: #f2efef;
  gap: 10px;
  z-index: 99;
  padding: 10px;
  border-radius: 5px;
  font-size: small;
}
.date__custom input {
  width: 120px;
}
.date__custom > button {
  padding: 0px 10px;
  border-radius: 5px;
  margin: 5px 0px;

  display: block;
}
.date__custom h1 {
  font-weight: 600;
  font-size: medium;
  display: block;
}
@media only screen and (max-width: 800px) {
  .Select_pay_mode {
    font-size: small;
  }
  .select__cunsuption,
  .route_select {
    width: 100%;
    font-size: small;
  }
  .flex__pai {
    display: block;
  }
  .flex__pai > div {
    width: 100%;
  }
  .for__single__pai {
    width: 100%;
    height: 300px;
  }
  .list_driver_code {
    margin-left: 10px;
  }
  .list_driver_name {
    margin-left: 10px;
  }
  .list_of_vehi {
    width: 100%;
  }
  .header__of__main__dashboard {
    display: block;
  }
  .header__of__main__dashboard > div {
    width: 100%;
    font-size: small;
  }
  .vehi_no {
    margin-top: 5px;
    margin-left: -18px;
  }
  .vehi_make {
    margin-top: -40px;
  }
  .trip_end_date {
    margin-left: 0px;
  }
  .trip_delivery {
    width: 200px;
    margin-left: -18px;
    margin-top: 10px;
  }
  .trip_contract {
    margin-left: -18px;
    width: 200px;
    margin-top: -30px;
  }
  .trip_route_start {
    margin-left: 10px;
    width: 300px;
    margin-top: -20px;
  }
  .trip_route_end {
    margin-left: 10px;
  }
  .trip_driver_name {
    margin-left: 10px;
  }
  .trip_client_name {
    margin-left: 10px;
  }
  .fuel_vehi_no {
    margin-left: -18px;
    margin-top: 10px;
  }
  .fuel_driver_code {
    margin-left: 10px;
    margin-top: -20px;
  }
  .fuel_driver_name {
    margin-left: 10px;
  }
  .fuel_contract_no {
    margin-left: -10px;
    margin-top: 10px;
  }
  .fuel_make {
    margin-left: 10px;
  }
  .fuel_route {
    margin-left: -5px;
  }
  .fuel_model {
    margin-left: 15px;
    margin-top: -20px;
  }
  .align__date_1 {
    display: block;
  }
  .list_start {
    display: block;
  }
  .list_of_vehi {
    display: block;
    margin-top: 10px;
  }
  .list1_start {
    display: block;
    width: 300px;
  }
  .list1_of_vehi {
    display: block;
    margin-left: 0px;
    margin-top: 10px;
  }
  .input_list_start {
    margin-top: 10px;
  }
  .input_list_end {
    margin-top: 10px;
  }
  .Select_pay_mode {
    margin-top: 10px;
  }
  .vehi_cost {
    margin-left: -5px;
  }

  .analytics_supp_select_1 {
    display: block;
    margin-left: 0px;
    margin-top: 10px;
  }
  .location_no {
    margin-left: 0px;
  }
  .analytics_Category_2_select {
    display: block;
  }
  .code_2 {
    margin-left: 10px;
  }
}
