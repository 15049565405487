.Profit_loss_box {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 90px;
}
.Profit_loss_first {
  display: flex;

  align-items: center;
}
.Profit_loss_Second {
  display: flex;

  align-items: center;

  margin-top: 13px;
}
.Profit_loss_third {
  display: flex;

  align-items: center;

  margin-left: 30px;
  margin-top: 10px;
}
.profit_from_date {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;

  margin-left: 10px;
  width: 325px;
}
.profit_to_date {
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 260px;
}
.profit_curr {
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profit_search {
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 487px;
}
.profit_Exc_rate {
  gap: 5px;
  display: flex;
  margin-left: 30px;
  width: 390px;
}
.profit_project {
  gap: 5px;
  display: flex;

  align-items: center;

  width: 430px;
}
.profit_division {
  display: flex;
  gap: 5px;

  align-items: center;
}
.profit_format {
  gap: 5px;
  display: flex;
  align-items: center;
  width: 415px;
}
.profit_btn {
  display: flex;
  gap: 20px;
  width: 404px;
}
.profit_btn1 {
  display: flex;
  gap: 30px;
}
.profit_View {
  height: 30px;
  background: #002d62;
  color: #fff;
  width: 50px;
}
.profit_View1 {
  height: 30px;
  background: #002d62;
  color: #fff;
  width: 130px;
}
.profit_print {
  height: 30px;
  background: #002d62;
  color: #fff;
  width: 70px;
}
.profit_print1 {
  height: 30px;
  background: #002d62;
  color: #fff;
  width: 130px;
}

@media only screen and (max-width: 600px) {
  .Profit_loss_first {
    display: block;
    align-items: center;
  }
  .profit_from_date {
    display: block;
    margin-left: 0px;
  }
  .profit_to_date {
    margin-top: 10px;
    display: block;
  }
  .profit_search {
    margin-top: 10px;
    display: block;

    width: 310px;
  }
  .profit_curr {
    margin-top: 10px;

    display: block;
  }
  .Profit_loss_Second {
    display: block;
  }
  .profit_Exc_rate {
    display: block;
    margin-top: 10px;
    margin-left: 0px;
    width: 310px;
  }
  .profit_project {
    display: block;
    margin-top: 10px;
    width: 324px;
  }
  .Profit_loss_third {
    display: block;

    margin-left: 0px;
  }
  .Profit_loss_box {
    height: 200px;
  }
  .profit_division {
    margin-top: 10px;
    display: block;
  }
  .profit_btn {
    width: 310px;
    margin-top: 10px;
  }
  .profit_btn1 {
    margin-top: 10px;
    gap: 17px;
  }
  .aside {
    overflow: scroll;
  }
  .curr_select {
    margin-top: 10px;
    width: 250px;
  }
  .Exc_input {
    margin-top: 10px;
  }
  .input_project {
    margin-top: 10px;
  }
}
