.General_box {
  margin-top: 20px;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 110px;
}
.General_first {
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
}
.General_Second {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
}
.G_account_name {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.header_gl_voucher_no{
width: 100px;
}
.G_acc_code {
  display: flex;
  gap: 5px;
  width: 343px;
  margin-top: 10px;
}
.header_gl_doc_no {
  width: 100px;
  padding-right: 40px;
}
.value_gl_doc_no {
  text-align: right;
  padding-right: 65px;
}
/* .header_gl_ref_no {
  padding-left: 51px;
} */

/* .value_gl_ref_no {
  text-align: right;
} */
/* .header_gl_desc {
  padding-left: 105px;
}
.value_gl_desc {
  padding-left: 105px;
} */
/* .header_gl_ref_no {
  padding-left: 15px;
}
.value_gl_ref_no {
  padding-left: 15px;
}
.header_gl_desc {
  padding-left: 15px;
}
.value_gl_desc {
  padding-left: 15px;
} */
.header_gl_ref_no {
  padding-left: 37px;
}
.value_gl_ref_no {
  padding-left: 40px;
  width: 100px
}
.header_gl_desc {
  width: 40px;
}
.value_gl_dr{
 
  text-align: right;
    padding-right: 35px;
}
.header_gl_date {
  width: 130px;
  padding-left: 20px;
}
.value_gl_date {
  padding-left: 20px;
}
.G_Start_Date {
  display: flex;
  margin-top: 20px;
  gap: 5px;
  width: 343px;
}
.G_End_Date {
  display: flex;
  margin-top: 20px;
  gap: 5px;
}
.header_nominal_name {
  padding-left: 10px;
}
.value_nominal_name {
  padding-left: 10px;
}
.header_nominal_ledger {
  padding-left: 10px;
}
.value_nominal_ledger {
text-align: left;
padding-left: 16px;
}
.value_trans_type{
  padding: 10px;
  width:160px;
}
.value_gl_voucher_no {
  text-align: right;
  padding-right: 35px;
}
.value_gl_desc {
  text-transform: uppercase;
  width:160px;
}
/* .value_gl_doc_no {
  text-align: right;
  padding-right: 45px;
} */

.header_gl_dr {
    padding-left: 55px;
    width:100px;
}

.header_gl_cr {
  padding-left: 72px;
}
.value_gl_cr {
  text-align: right;
  padding-right: 10px;
  width: 100px;
}
@media only screen and (max-width: 600px) {
  .General_Second {
    display: block;
  }
  .General_first {
    display: block;
    margin-top: 20px;
  }
  .G_Start_Date {
    display: block;
  }
  .G_End_Date {
    display: block;
  }
  .G_account_name {
    display: block;
    margin-top: 15px;
  }
  .G_acc_code {
    display: block;
  }
  .General_box {
    height: 240px;
  }
  .header_nominal_name {
    width: 300px;
  }
  .value_gl_desc {
    text-transform: uppercase;
    padding-left: 15px;
  }
  .header_gl_desc {
    padding-left: 15px;
  }
  .header_gl_doc_no {
    padding-left: 15px;
  }
  .header_gl_ref_no {
    padding-left: 42px;
}
.header_gl_cr {
  padding-left: 38px;
}
}
