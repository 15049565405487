.analytics_Client_select {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 13px;
}
/* .analytics_Client_select > aside > p {
  font-weight: 600;
} */

.Select_Client_code {
  width: 130px;
}
.Select_Client_name {
  width: 297px;
}
@media only screen and (max-width: 660px) {
  .analytics_Client_select {
    margin-left: 0px;
    display: block;

    margin-bottom: 26px;
  }
  .Select_Client_code {
    margin-top: 10px;
  }
  .Select_Client_name {
    margin-top: 10px;
  }
}
