.box {
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 10px;
  height: 115px;
  font-size: 15px;
}
.value_qty {
  padding-left: 30px;
}
.ph_total_sales {
  display: flex;
}
.ph_total_qty {
  display: flex;
}
.ph_total_amt {
  display: flex;
}
.sh_total_sales {
  display: flex;
}
.sh_total_amt {
  display: flex;
}
.sh_total_qty {
  display: flex;
}
.sh_search {
  height: 30px;
  width: 300px;
  background-color: white;
}
.Line_1 {
  display: flex;
  margin-left: 10px;
}
.Line_2 {
  display: flex;
}
.Line_3 {
  display: flex;

  margin-left: 10px;
}
.stock_search {
  height: 30px;
  width: 300px;
  background-color: white;
}

.box_1 {
  display: flex;
  margin-top: 25px;
}
.Search_Part_no {
  display: flex;
  width: 330px;
  gap: 5px;
  align-items: center;
}
.Description {
  display: flex;
  gap: 10px;
  align-items: center;

  margin-top: 5px;
}
.Frozen {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
}
.Window_button {
  display: flex;
  gap: 5px;
  margin-left: 20px;

  height: 38px;
}
.View_Image {
  margin-left: 67px;
  background-color: #002d62;
  color: white;
  height: 38px;
}
.Enquiry {
  background-color: #002d62;
  color: white;
  height: 38px;
  width: 65px;
}
.Row_height {
  margin-right: 8px;
  margin-top: 5px;
}
.Desc_box {
  width: 400px;
  height: 30px;
  background-color: white;
  margin-left: 5px;
}
.Sort_by {
  align-items: center;
  display: flex;
  gap: 5px;
  width: 310px;
}
.Selected {
  align-items: center;
  display: flex;
  gap: 10px;
  width: 398px;

  margin-left: 10px;
}
.Selling_price {
  align-items: center;
  display: flex;
  gap: 15px;
  width: 340px;
  margin-top: 10px;
  margin-left: 18px;
}
.Window_button1 {
  display: flex;
  gap: 5px;
  margin-top: 10px;
  margin-left: 38px;
}
.header_purchase_type {
  width: 162px;
}
.header_purchase_grn {
  padding-left: 20px;
}
.Search_button {
  background-color: #002d62;
  color: white;
  height: 38px;
  width: 72px;
}
.Clear_btn {
  background-color: #002d62;
  color: white;
  height: 38px;
  width: 67px;
}
.Selected_input {
  height: 30px;
  background-color: white;
  margin-left: 15px;
}
.Sp_input {
  height: 30px;
  background-color: white;
  width: 179px;
  margin-top: 10px;
  margin-left: -4px;
}
.select_sort_by {
  margin-left: 54px;
}
.Search_on {
  display: flex;
  align-items: center;
  width: 310px;
  gap: 36px;
}
.Like {
  display: flex;
  align-items: center;
  gap: 55px;
  width: 380px;
  margin-left: 21px;
}
.Window_button2 {
  display: flex;
  gap: 5px;
  width: 280px;
  margin-top: 6px;
}
.Barcode {
  margin-top: -7px;
}
.Refresh_List {
  display: flex;
  background-color: #002d62;
  color: white;
  height: 36px;
  align-items: center;
  width: 86px;
}

.selected-row {
  background-color: yellow;
}
.Hide {
  background-color: #002d62;
  color: white;
  height: 38px;
  width: 55px;
}
.Show_cost {
  background-color: #002d62;
  color: white;
  height: 38px;
  width: 85px;
}
.View_serials {
  background-color: #002d62;
  color: white;
  height: 38px;
  width: 95px;
}
.input_Barcode {
  height: 30px;
  background-color: white;
}
.input_Like {
  width: 350px;
  height: 30px;
  background-color: white;
}

.input_search_on {
}
.box_1 {
  display: flex;
  margin-left: 10px;
}
.box_2 {
  display: flex;
}
.box_3 {
  display: flex;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 51px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  width: 280px;
}
.Item_search {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 314px;
}
.Collapse {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 200px;
}
.Dead_stock {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}
.Fast_Moving {
  display: flex;
  align-items: center;
  gap: 5px;
}
.slow_Moving {
  display: flex;
  align-items: center;
  gap: 5px;
}
.stock_table {
  /* height: 40vh; */
  /* overflow-y: auto; */

  /* overflow: auto; */
  width: 100%;
  margin: 50px 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.stock_icons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 15px;
  margin-right: 40px;
}

.stock_Category {
  display: flex;
  gap: 5px;
  margin-top: 4px;
}
.Manufacture {
  display: flex;
  gap: 5px;
  margin-top: 7px;
}
.stock_SubCategory {
  display: flex;
  gap: 5px;
  margin-top: 4px;
}
.Main_Name {
  display: flex;
  gap: 5px;
  margin-top: 7px;
}
.stock_item_code {
  display: flex;
  gap: 5px;
  margin-top: 4px;
}
.stock_OEM_no {
  display: flex;
  gap: 5px;
  margin-top: 7px;
}
.box_4 {
  display: flex;
  gap: 13px;
  font-size: 15px;
  margin-left: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 440px;
}

.Hide1 {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.thead_stock {
  background: #002d62;
  color: #fff;
  font-weight: 500;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 97%;
  z-index: 100;
  border-radius: 5px;
  outline: 1.5px solid #585757;
}
.table_1 {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
}
.table_2 {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
}
.table_4 {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
  z-index: 0;
}
.tr_test1 {
  font-size: small;
  padding: 0 5px;
  text-align: left;
}
.tr_test2 {
  border-bottom: 1px solid lightgrey;
  padding: 20px 20px 20px 0px;
  font-size: small;
}
.tr_test2 :hover {
  cursor: pointer;
}
.Load_stock_button {
  background: #002d62;
  color: #fff;
  height: 38px;
  width: 70px;
}
.Window_button5 {
  display: flex;
  gap: 10px;
}
/* Normal state */
.Window_button5 button {
  background-color: #002d62;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
}

/* Hover state */
.Window_button5 button:hover {
  background-color: #002d62;
  color: white;
}

/* Active state */
.Window_button5 button.active {
  background-color: yellow;
  color: black;
}

.Sales_history {
  height: 40px;
  background: #002d62;
  color: #fff;
  width: 400px;
  margin-left: 20px;
}
.btn_purchase {
  height: 40px;
  background: #002d62;
  color: #fff;
  width: 400px;
}
.btn_purchase:hover {
  background-color: lightgoldenrodyellow;
  transition: all 0.4s ease-in-out;
  color: black;
}
.btn_purchase:focus {
  background-color: lightgoldenrodyellow;
  transition: all 0.4s ease-in-out;
  color: black;
}
.Sales_history:hover {
  background-color: lightgoldenrodyellow;
  transition: all 0.4s ease-in-out;
  color: black;
}
.Sales_history:focus {
  background-color: lightgoldenrodyellow;
  transition: all 0.4s ease-in-out;
  color: black;
}
.btn_Qty {
  height: 40px;
  background: #002d62;
  color: #fff;
  width: 250px;
}
.btn_stock {
  height: 40px;
  background: #002d62;
  color: #fff;
  width: 400px;
}
.btn_stock:hover {
  background-color: lightgoldenrodyellow;
  transition: all 0.4s ease-in-out;
  color: black;
}
.btn_stock:focus {
  background-color: lightgoldenrodyellow;
  transition: all 0.4s ease-in-out;
  color: black;
}
.btn_branch {
  height: 40px;
  background: #002d62;
  color: #fff;
  width: 250px;
}
.stock_second {
  display: flex;
  margin-top: 20px;
}
.stock_part_no {
  display: flex;
  gap: 10px;
  margin-left: 10px;
  width: 318px;
}
.stock_desc {
  display: flex;
  gap: 10px;
}

.box_6 {
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 15px;
  height: 110px;
  font-size: 15px;
}
.box8 {
  background-color: aliceblue;
  margin-top: 15px;
  height: 110px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.purchase_history_first {
  display: flex;
}
.purchase_history_total {
  display: flex;
  margin-top: 25px;
  margin-left: 10px;
  gap: 150px;
}
.purchase_start_date {
  display: flex;
  margin-top: 10px;
  align-items: center;
  margin-left: 10px;
  gap: 5px;
  width: 260px;
}
.purchase_end_date {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 5px;
  width: 260px;
}
.auto {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 5px;
  width: 260px;
}
.stock_act_second {
  display: flex;
}
.r_btn {
  height: 30px;
  background: #002d62;
  color: #fff;
  width: 100px;
}
.window_refresh_btn1 {
  margin-top: 20px;
}

.line_4 {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
}
.line_5 {
  display: flex;
  margin-left: 10px;
  margin-top: 25px;
}
.Activity {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 10px;
  width: 366px;
}
.Location {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  width: 440px;
}
.Window_button7 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  width: 175px;
}
.Print {
  height: 40px;
  background: #002d62;
  color: #fff;
  width: 200px;
}
.View_stock {
  height: 40px;
  background: #002d62;
  color: #fff;
  width: 200px;
}
.Datewise_Activity {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  margin-left: 30px;
}
.stock_start_date {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 347px;
}
.stock_end_date {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 514px;
}
.Opening_balance {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-left: 50px;
}
.input_opening_balance {
  height: 30px;
}
.select_activity {
  width: 200px;
}
.select_Location {
  width: 350px;
}
.stock_actvity_table {
  height: 40vh;
  overflow-y: auto;

  overflow: auto;
  width: 100%;
  margin-top: -30px;

  align-items: center;
  display: flex;
  justify-content: center;
}
.line_7 {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
}
.Total_in {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 290px;
}
.Total_out {
  display: flex;
  align-items: center;
  gap: 5px;
}
.location_stock {
  margin-top: 20px;
  display: flex;
  gap: 5px;
  font-size: 15px;
  justify-content: flex-end;
}
.h4 {
  margin-top: 10px;
  margin-left: 10px;
  color: brown;
  display: flex;
  align-items: center;
  justify-content: center;
}
.purchase_start_date {
  display: flex;
}
.purchase_end_date {
  display: flex;
}
.auto {
  display: flex;
}
.box9 {
  height: 140px;
  margin-top: 20px;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sales_history_first {
  display: flex;
}
.sales_history_second {
  display: flex;
  margin-top: 20px;
}
.sales_history_loc {
  gap: 5px;
  z-index: 10;
  justify-content: center;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.table_3 {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
}

.sales_from_date {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
  width: 250px;
}
.sales_to_date {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 5px;
  width: 590px;
}
.sales_client {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
  gap: 5px;
  width: 450px;
}
.sales_history_total {
  display: flex;
  gap: 65px;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
}
.window_refresh_btn {
  margin-top: 25px;
}
.sales_resfresh_btn {
  height: 30px;
  background: #002d62;
  color: #fff;
  width: 100px;
}
.input_project {
  width: 250px;
}
.stock_quantity {
  width: 160px;
  text-align: right;
  padding-right: 70px;
}

.header_quantity_stock {
  padding-left: 0px;
}
.stock_sp {
  width: 158px;
}
@media only screen and (max-width: 600px) {
  .box {
    height: 334px;
  }
  .Line_1 {
    display: block;
    margin-left: 0px;
  }
  .Selected {
    display: block;
  }
  .Description {
    margin-left: 7px;
    display: block;
  }
  .Desc_box {
    margin-top: 10px;
    width: 190px;
  }
  .Sp_input {
    margin-left: 6px;
  }
  .Line_2 {
    display: block;
  }
  .Selling_price {
    display: block;
    width: 310px;
    margin-left: 7px;
  }
  .Like {
    margin-left: -30px;
    display: block;
    width: 310px;
  }
  .Window_button {
    margin-left: 0px;
    margin-top: -3px;

    height: 38px;
  }
  .sales_history_first {
    display: block;
  }
  .sales_from_date {
    margin-left: 0px;
    display: block;
  }
  .sales_to_date {
    width: 310px;
    display: block;
  }
  .Selected_input {
    margin-top: 10px;
    margin-left: 0px;
  }
  .sales_history_loc {
    display: block;
    margin-right: 125px;
    margin-top: 10px;
  }
  .sales_history_second {
    display: block;
    margin-top: 10px;
  }
  .sales_client {
    display: block;
    margin-left: 0px;
    width: 310px;
  }
  .sales_history_total {
    display: block;
    margin-top: 20px;
  }

  .box9 {
    height: 360px;
  }
  .box8 {
    height: 295px;
  }
  .stock_quantity {
    padding-right: 22px;
  }
  .purchase_end_date {
    display: block;
  }
  .purchase_history_first {
    display: block;
  }
  .purchase_start_date {
    display: block;
    margin-left: -1px;
  }
  .sales_client {
    display: block;
    width: 374px;
  }
  .purchase_history_total {
    display: block;
    margin-left: 0px;
    margin-top: 20px;
  }
  .line_5 {
    display: block;
    margin-left: 0px;
  }
  .stock_act_second {
    display: block;
  }
  .stock_end_date {
    width: 347px;
  }

  .Opening_balance {
    margin-left: 0px;
    margin-top: 20px;
  }
  .box_6 {
    height: 320px;
  }
  .table_1 {
    border-collapse: separate;
  }
  .stock_table {
    overflow: auto;
  }
  .Window_button1 {
    display: block;
  }
  .Window_button {
    margin-left: -28px;
    margin-top: 10px;
  }
  .header_quantity_stock {
    padding-left: 15px;
  }
  .stock_search {
    margin-top: 10px;
  }
  .header_purchase_grn {
    padding-left: 9px;
    width: 97px;
  }
  .header_purchase_supp_name {
    width: 200px;
    padding-left: 50px;
  }
  .value_supp_name {
    padding-left: 50px;
  }
  .header_purchase_qty {
    width: 100px;
    padding-left: 10px;
  }
  .value_qty {
    text-align: right;
    padding-right: 26px;
  }
  .header_purchase_rate_exc {
    width: 100px;
  }
  .header_purchase_rate_inc {
    width: 100px;
  }
  .posted_by {
    width: 100px;
  }
  .stock_start_date {
    display: block;
  }
  .stock_end_date {
    display: block;
    margin-top: 10px;
  }
  .header_doc_type {
    width: 100px;
  }
  .header_doc_no {
    width: 100px;
  }
  .header_doc_date {
    width: 100px;
  }
  .header_client_name {
    width: 200px;
  }
  .header_loc {
    width: 200px;
  }
  .header_rate_exc {
    width: 100px;
  }
  .header_rate_inc {
    width: 100px;
  }
  .header_posted_by1 {
    width: 110px;
  }
  .header_date {
    width: 100px;
  }
  .header_locat {
    width: 100px;
  }
  .sh_search {
    margin-top: 10px;
  }
  .sh_total_sales {
    margin-top: 10px;
  }
  .sh_total_amt {
    margin-top: 10px;
  }
  .ph_total_sales {
    margin-top: 10px;
  }

  .ph_total_amt {
    margin-top: 10px;
  }
  .value_ph_amt_exc {
    text-align: right;
    padding-right: 26px;
  }
  .value_ph_amt_inv {
    text-align: right;
    padding-right: 26px;
  }
}
