.Trial_balance {
  display: flex;
  margin-top: 15px;
  background-color: aliceblue;
  height: 90px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.trial_as_at {
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 5px;
  width: 250px;
}
.as_at {
  border-radius: 5px;
  border: 1px solid;
}
.trial_balance {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 440px;
}
@media only screen and (max-width: 600px) {
  .Trial_balance {
    display: block;
    height: 130px;
  }
  .balance_Like {
    align-items: center;

    width: 295px;
    margin-top: 10px;
    display: block;
  }
  .trial_as_at {
    margin-left: 0px;
    display: block;
  }
  .trial_balance {
    margin-top: 10px;
    width: 310px;
  }
  .as_at {
    margin-top: 10px;
  }
  .input_Like {
    margin-top: 10px;
  }
}
