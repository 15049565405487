/* loader33,
.loader33:before,
.loader33:after {
  border-radius: 50%;

  width: 2.5em;

  height: 2.5em;

  animation-fill-mode: both;

  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader33 {
  color: #ffffff;

  font-size: 5px;

  position: relative;

  text-indent: -9999em;

  transform: translateZ(0);

  animation-delay: -0.16s;

  top: -13px;
}

.loader33:before,
.loader33:after {
  content: "";

  position: absolute;

  top: 0;
}

.loader33:before {
  left: -3.5em;

  animation-delay: -0.32s;
}

.loader33:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.loader3Small {
  position: relative;
  width: 85px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#041e7b 50px, transparent 0),
    linear-gradient(#041e7b 50px, transparent 0),
    linear-gradient(#041e7b 50px, transparent 0),
    linear-gradient(#041e7b 50px, transparent 0),
    linear-gradient(#041e7b 50px, transparent 0),
    linear-gradient(#041e7b 50px, transparent 0);
  background-position: 0px center, 15px center, 30px center, 45px center,
    60px center, 75px center, 90px center;
  animation: rikSpikeRoll 0.65s linear infinite alternate;
}
@keyframes rikSpikeRoll {
  0% {
    background-size: 10px 3px;
  }
  16% {
    background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px;
  }
  33% {
    background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px,
      10px 3px;
  }
  50% {
    background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px,
      10px 3px;
  }
  66% {
    background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px,
      10px 3px;
  }
  83% {
    background-size: 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px,
      10px 3px;
  }
  100% {
    background-size: 10px 3px, 10px 3px, 10px 3px, 10px 10px, 10px 30px,
      10px 50px;
  }
} */

.loader3 {
  width: 8px;
  height: 10px;
  border-radius: 4px;
  display: block;
  margin: 0px 30px;
  position: relative;
  color: #160245;
  background-color: #160245;
  box-sizing: border-box;
  animation: loader3Small 0.3s 0.3s linear infinite alternate;
}

.loader3::after,
.loader3::before {
  content: "";
  width: 8px;
  height: 10px;
  border-radius: 4px;
  background-color: #160245;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: loader3Small 0.3s 0.45s linear infinite alternate;
}
.loader3::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes loader3Small {
  0% {
    height: 18px;
  }
  100% {
    height: 4px;
  }
}
