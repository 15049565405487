.align1_date {
  margin: 30px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  background-color: aliceblue;
  border-radius: 5px;
}
.align1_date > div {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 40%;
  font-weight: 500;
}
.align1_date > div > div > p {
  font-weight: 600;
}
/* .align1_date > div > div > input {
  height: 30px;
  width: 130px;
  border-radius: 5px;
  border: 1px solid;
/* }  */

.Stock_category {
  display: flex;
  font-weight: 600;
  gap: 10px;
  align-items: center;
}
.lbl_Category {
  width: 93px;
}
.lbl_SubCategory {
  width: 133px;
}
@media only screen and (max-width: 660px) {
  .align1_date {
    display: block;
  }
  .Select_Category {
    width: 199px;
  }
  .align1_date > div {
    width: 58%;
    display: block;
    margin-left: -18px;
  }
  .Stock_category {
    display: block;
    margin-top: 20px;
    margin-left: -18px;
  }
}
