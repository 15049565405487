.back_btn {
  padding: 10px 10px 10px 0px;
}
.app {
  text-align: center;
}
.info {
  border: none;
  background-color: none;
}
.closed_circle {
  position: absolute;
  right: 0;
  top: 0;
}
.nmmm {
  z-index: 0;
}
.from_1 {
  display: flex;
  width: 258px;
  gap: 10px;
  margin-top: 15px;
}
.to_1 {
  display: flex;
  width: 235px;
  gap: 10px;
  margin-top: 15px;
}
.Doc_No_1 {
  display: flex;
  gap: 10px;
  width: 335px;
  margin-top: 15px;
}
.Approve_1 {
  margin-top: -8px;

  margin-left: 7px;
}
.Payment_tr_1 > th {
  font-weight: 500;
  width: 159px;
  text-align: left;
}
.account_name {
  width: 277px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.conversion_rate {
  text-align: right;
  padding-right: 10px;
}
.journal_view_total_cr_dr {
  padding-right: 100px;
}
.value_journals_curr {
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .Doc_No_1 {
    display: block;
    gap: 5;
    margin-left: 1px;
    width: 300px;
  }
  .Doc_No_1 > div > input {
    margin: 10px 0px 10px;
  }
  .from_1 {
    display: block;
  }
  .from_1 > div > input {
    margin: 10px 0px 10px;
  }
  .to_1 {
    display: block;
  }
  .to_1 > div > input {
    margin: 10px 0px 10px;
  }
  .Approve_1 {
    margin-top: 10px;
    margin-left: 0px;
  }
  .back_btn {
    padding: 10px 6px 10px 7px;
  }
  .Modal {
    overflow: scroll;
  }
  .view_journals_cr_dr {
    padding-left: 30px;
  }
  .view_journals_amount {
    padding-left: 40px;
  }

  .view_journals_conv_rate {
    padding-left: 20px;
  }
  .value_journals_cr_dr {
    padding-left: 32px;
  }
  .value_journals_amount {
    padding-left: 49px;
  }
  .value_journals_curr {
    text-align: center;
  }
  .journal_view_total_cr_dr {
    padding-left: 50px;
  }
}
