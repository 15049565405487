.balance_sheet_box_1 {
  display: flex;
  margin-top: 15px;
  background-color: aliceblue;
  height: 90px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.bal_as_at {
  display: flex;
  gap: 5px;
  margin-left: 10px;
  width: 250px;
  align-items: center;
}
.balance {
  display: flex;
}
.bal_currency {
  display: flex;
  gap: 5px;
  align-items: center;

  width: 233px;
  margin-left: 20px;
}
.Bal_Excl_rate {
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: center;
  width: 320px;
}
.input_Bal_exc_rate {
  height: 30px;
}
.balance_sheet_view_button {
  display: flex;
  gap: 5px;
  width: 273px;
}
.balance_sheet_acc_button {
  display: flex;
  gap: 5px;
}
.bal_view_button {
  background-color: #002d62;
  color: white;
  height: 38px;
  width: 50px;
}
.bal_view_Exp_button {
  background-color: #002d62;
  color: white;
  height: 38px;
}
.bal_account {
  background-color: #002d62;
  color: white;
  height: 38px;
}
.bal_acc_chart {
  background-color: #002d62;
  color: white;
  height: 38px;
}
.balance_Like {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 434px;
}
.group_index {
  padding-left: 77px;
}
.asset {
  padding: 10px;
  text-transform: uppercase;
}
.header_group {
  padding-left: -20px;
}
.driver {
  text-transform: uppercase;
}
@media only screen and (max-width: 600px) {
  .balance {
    display: block;
  }
  .bal_as_at {
    margin-left: 0px;
    margin-top: -30px;
    display: block;
  }
  .balance_Like {
    width: 310px;
    display: block;
  }
  .bal_currency {
    margin-left: 0px;
    margin-top: 10px;
  }
  .Bal_Excl_rate {
    margin-top: 10px;
  }
  .balance_sheet_box_1 {
    height: 153px;
  }
  .header_group {
    padding-left: 16px;
  }
  .header_group_index {
    padding-left: 10px;
  }
  .header_level {
    padding-left: 10px;
  }
  .header_amount {
    padding-left: 10px;
  }
}
