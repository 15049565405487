.dashboards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.for__main__dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 50px;
  gap: 30px;
  height: 50vh;
}
.align__img__and__heading > img {
  mix-blend-mode: multiply;
  width: 200px;
  height: 150px;
}
.align__img__and__heading {
  border-radius: 20px;
  padding: 10px -1px 15px 10px;
  height: 200px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  font-weight: 600;
  color: rgb(6, 6, 45);
  font-size: larger;
}
.align__img__and__heading:hover {
  box-shadow: rgba(5, 14, 59, 0.35) 0px 5px 15px;
  cursor: pointer;
  transform: scale(1.07);
}
.for__main__dashboard h3 {
  height: 40px;
}
@media only screen and (max-width: 660px) {
  .for__main__dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    gap: 30px;
    flex-direction: column;
    height: auto;
  }
}
