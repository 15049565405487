.creditor_box {
  margin-top: 20px;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 90px;
}

.creditors_first {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.creditors_from_date {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-left: -9px;
  width: 261px;
  margin-top: 25px;
}

.creditor_search {
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 487px;
  margin-top: 25px;
}

.value_exc {
  width: 100px;
  text-align: right;
  padding-right: 42px;
}
.value_vend_code {
  padding-left: 5px;
}
@media only screen and (max-width: 660px) {
  .creditors_from_date {
    display: block;

    justify-content: flex-start;

    margin-left: 0px;
  }
  .value_exc {
    padding-right: 19px;
    width: 308px;
  }

  .creditor_search {
    display: block;
    justify-content: flex-start;

    width: 310px;
  }
  .creditor_box {
    height: 155px;
  }
  .value_vend_code {
    padding-left: 0px;
  }
  .vend_name {
    padding-left: 10px;
  }
  .amount {
    padding-left: 10px;
  }
  .foreign_curr {
    padding-left: 30px;
  }
  .header_amount {
    padding-left: 30px;
  }
  .creditors_first {
    display: block;
   
}
}
