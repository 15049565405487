.scroll {
  width: 100%;
}
.sec_line_1 {
  display: flex;
  gap: 100px;
}
.view_doc_no {
  padding-left: 45px;
}

.view_acc_code {
  padding-left: 35px;
}

.view_acc_name {
  margin: 2px 2px;
  padding: 15px 15px 10px 0px;
  width: 257px;
}

.view_cr_dr {
  padding-left: 50px;
}

.view_amount {
  margin: 2px 2px;
}

.view_conv_rate {
  text-align: right;
  margin: 4px 4px;
  padding: 0px 17px 0px 0px;
}
.petty_cash_vouchers {
  display: flex;

  justify-content: flex-start;
  gap: 40px;
  padding: 20px;
  font-size: 16px;
  margin-left: 200px;
}
.Narration {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  gap: 40px;
  margin-left: 220px;
}
.Payment_tr_2 > th {
  font-weight: 500;
  width: 159px;
  text-align: left;
}
.header_petty_cash_voucher_date {
  padding-right: 45px;
}
.header_petty_cash_voucher_amount {
  padding-right: 70px;
}

.value_petty_cash_docno {
  text-align: right;
  padding-right: 68px;
}

.value_petty_cash_date {
  text-align: center;
  margin: 2px 2px;
  padding: 20px 30px 20px 0px;
}

.value_petty_cash_account {
  text-align: left;
  padding: 0px 5px 0px 0px;
  width: 251px;
}

.value_petty_cash_amount {
  textalign: right;
  margin: 2px 2px;
  padding: 0px 0px 0px 42px;
}

.value_petty_cash_curr {
  text-align: center;
  margin: 4px 4px;
  padding: 0px 25px 0px 0px;
}
.View_dr_cr_header {
  padding-left: 40px;
}
.total_cr_amount {
  text-align: right;
  padding-right: 85px;
  padding-block: 15px;
}
@media only screen and (max-width: 600px) {
  .view_doc_no {
    padding-left: 20px;
  }
  .petty_cash_vouchers {
    display: block;
    padding: 20px;
    font-size: small;
    margin-left: 0px;
  }
  .Narration {
    display: block;
    margin-left: 20px;
    margin-top: -13px;
    font-size: small;
  }
  .view_acc_name {
    margin: 2px 2px;
    padding: 15px 69px 10px 0px;
    width: 300px;
  }
  .View_dr_cr_header {
    padding-left: 13px;
  }
  .view_cr_dr {
    padding-left: 18px;
  }
  .view_petty_amount_header {
    padding-left: 30px;
  }
  .view_amount {
    margin: 2px 2px;
    padding-left: 40px;
  }
  .total_cr_amount {
    text-align: right;
    padding-right: 0px;
  }
  .header_conv_rate {
    padding-left: 20px;
  }
}