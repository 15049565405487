.Analyze {
  background-color: #002d62;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
}

.align__date {
  margin: 10px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: aliceblue;
  border-radius: 5px;
}
.align__date > div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
}
.gapfor__date {
  display: flex;
  gap: 5px;
}
/* .align__date > div > div > p {
  font-weight: 600;
} */
.align__date > div > div > input {
  height: 30px;
  width: 130px;
  border-radius: 5px;
  border: 1px solid;
}
.analytics_supp_select {
  width: 400px;
}
.Supp_analytics_Supp_Name {
  margin: 0px 10px 10px 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px;
  background-color: aliceblue;
  border-radius: 5px;
}
.Supp_analytics_Supp_Name_1 {
  margin: 0px 10px 0px 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px;
  background-color: aliceblue;
  border-radius: 5px;
}
.analytics_supp_select > aside > p {
  font-weight: 600;
  margin: 5px 0px;
}
.Select_Supp_name_1 {
  width: 370px;
}
.select__font {
  font-size: small;
  width: 330px;
  margin-right: -10px;
}
.Supp_analytics_Supp_Name_2 {
  /* margin: 0px 10px 10px 10px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* padding: 10px; */
  background-color: aliceblue;
  border-radius: 5px;
}

@media only screen and (max-width: 660px) {
  .align__date > div {
    display: block;
    width: 63%;
  }
  .Select_Supp_name_1 {
    width: 340px;
  }
  .Supp_analytics_Supp_Name {
    display: block;
  }
  .analytics_supp_select {
    width: 100%;
  }
  .align__date > div > div > p {
    margin: 10px 0px;
  }
  .align__date {
    display: block;
  }
  .Supp_analytics_Supp_Name_1 {
    margin: 0px;
    display: block;
    margin-top: -23px;
    /* justify-content: space-between; */
  }
  .Select_Sub_Category {
    width: 303px;
    margin-top: 10px;
  }
}
