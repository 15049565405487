.search_filter {
  display: flex;
  gap: 10px;
  margin-left: 150px;
  margin-top: 20px;
}

.search_filter_rfq {
  display: flex;
  gap: 10px;
  margin-left: 210px;
  margin-top: 20px;
}

.Payment_data_header {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 500;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 37px;
}

.thead {
  background-color: #002d62;
  height: 50px;
  border: none;
  color: white;
  z-index: 100;
}

.Payment_tr th:nth-child(2) {
  padding-right: 40px;
  width: 172px;
}

.Payment_tr th:nth-child(3) {
  padding-left: 18px;
}
.Payment_tr_3 > th {
  font-weight: 500;
  width: 159px;
  text-align: left;
}

.Payment_tr th:nth-child(4),
.Payment_tr td:nth-child(4) {
  text-align: left;
}

.payments_view_docno {
  display: flex;

  justify-content: flex-start;
  gap: 40px;
  padding: 20px;
  font-size: 20px;
  margin-left: 150px;
}
.payments_narration {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  gap: 40px;
  margin-left: 168px;
}

.Payment_tr th:nth-child(5),
.Payment_tr td:nth-child(5) {
  text-align: left;
  padding-left: 35px;
}

.Payment_tr th:nth-child(6),
.Payment_tr td:nth-child(6) {
  text-align: left;
}

.Payment_tr th:nth-child(7),
.Payment_tr td:nth-child(7) {
  width: 164px;
}

.Payments_tbody {
  background-color: white;
  text-align: left;
}

.Payments_tbody_tr {
  border-bottom: 1px solid antiquewhite;
  cursor: pointer;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

/* **************************************** New Template ************************* */
.interface__name {
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  margin: 10px 0px 10px 30px;
  text-transform: uppercase;
}

.header__section {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.filter__section {
  display: flex;
  margin-top: 1rem;
  margin-left: 30px;
  gap: 15px;
  /* justify-content: flex-start; */
}
.buttons__section {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  gap: 10px;
}

.table__header {
  background-color: #e1e6e9;
  height: 50px;
  border: none;
  color: black;
  z-index: 100;
}

.table__style {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* font-weight: 500; */
  font-size: 12px;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
  width: 95%;
}

.table__style__dashboard {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* font-weight: 500; */
  font-size: 12px;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
  width: 50%;
}

.button__style {
  background-color: #3496e6;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  /* display: inline-block; */
  font-size: 16px;
  /* font-weight: 400; */
  /* line-height: 1.15385; */
  height: 40px;
  width: fit-content;
  padding: 0px 10px 0px 10px;
  /* margin-right: 10px; */
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  align-items: center;
}

.checkbox__style {
  width: 20px;
  height: 20px;
  border-radius: 25px !important;
  margin-left: 10px;
}
