.Payment_data_header {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 500;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 37px;
}

.thead {
  background-color: #002d62;
  height: 50px;
  border: none;
  color: white;
  z-index: 100;
}

.Payment_tr th:nth-child(2) {
  padding-right: 40px;
  width: 172px;
}

.Payment_tr th:nth-child(3) {
  padding-left: 18px;
}
.Payment_tr_3 > th {
  font-weight: 500;
  width: 159px;
  text-align: left;
}

.Payment_tr th:nth-child(4),
.Payment_tr td:nth-child(4) {
  text-align: left;
}

.payments_view_docno {
  display: flex;

  justify-content: flex-start;
  gap: 40px;
  padding: 20px;
  font-size: 20px;
  margin-left: 150px;
}
.payments_narration {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  gap: 40px;
  margin-left: 168px;
}

.Payment_tr th:nth-child(5),
.Payment_tr td:nth-child(5) {
  text-align: left;
  padding-left: 35px;
}

.Payment_tr th:nth-child(6),
.Payment_tr td:nth-child(6) {
  text-align: left;
}

.Payment_tr th:nth-child(7),
.Payment_tr td:nth-child(7) {
  width: 164px;
}

.Payments_tbody {
  background-color: white;

  text-align: left;
}

.Payments_tbody_tr {
  border-bottom: 1px solid antiquewhite;
}

.Violation_btn {
  background-color: #002d62;

  color: white;

  cursor: pointer;
  width: 39px;
  height: 33px;
}
.Titles {
  color: white;
  font-size: 25px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Violation_btn:hover,
.Violation_btn:focus {
  background-color: #07c;
}
.Violation_btn:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
}

.Violation_btn:active {
  background-color: #0064bd;

  box-shadow: none;
}
.View {
  background-color: white;

  border: 1px solid transparent;

  border-radius: 3px;

  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;

  box-sizing: border-box;

  color: black;

  cursor: pointer;

  display: inline-block;

  font-size: 20px;

  font-weight: 400;

  line-height: 1.15385;
  height: 35px;
  width: 75px;

  margin: 0;

  outline: none;

  position: relative;

  text-align: center;

  text-decoration: none;

  user-select: none;

  -webkit-user-select: none;

  touch-action: manipulation;

  vertical-align: baseline;

  white-space: nowrap;
  align-items: center;
}

.View_Approve {
  background-color: #002d62;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  /* display: inline-block; */
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15385;
  height: auto;
  width: auto;
  padding: 5px;
  margin-right: 10px;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  align-items: center;
}

.Approve {
  display: flex;
  margin-left: 17px;
  align-items: center;
  margin-top: -15px;
}
.Approve_4 {
  display: flex;
  margin-left: 17px;
  align-items: center;
}
.from_unp {
  display: flex;

  background-color: aliceblue;
  padding: 15px;
  border-radius: 10px;
}
.Vend_unp {
  display: flex;
  margin-top: 17px;

  background-color: aliceblue;
  padding: 15px;
  border-radius: 10px;
}
.pagination {
  display: flex;

  justify-content: center;

  gap: 20px;

  padding: 25px;
  margin-top: 40px;
}

.enter_no {
  border: none;

  border-bottom: 1px solid black;
}

.end {
  display: flex;

  justify-content: flex-end;

  padding: 9px 15px;
}

.cliploader {
  display: block;

  text-align: center;

  margin: 0 auto;
}

.loader {
  text-align: center;

  display: flex;

  justify-content: center;

  align-items: center;
}

.Reload {
  background-color: #002d62;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15385;
  height: 35px;
  width: 110px;
  margin: 10px;
  margin-left: 20px;
  margin-top: -6px;
  outline: none;
  padding: 5px 0.8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  user-select: none;

  -webkit-user-select: none;

  touch-action: manipulation;

  vertical-align: baseline;
  white-space: nowrap;
  margin-left: -30px;
}
.Filter {
  display: flex;
  margin-top: 20px;
  background-color: aliceblue;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
}
.Unapp_payment {
  background-color: #002d62;
}
.nav_1 {
  display: flex;
  gap: 50px;
}
.back_btn {
  display: flex;
  gap: 20px;
}
.Vendor {
  display: flex;
  margin-left: 20px;
  gap: 5px;
}
.Doc_No {
  display: flex;
  gap: 5px;
  margin-left: 81px;
}
.from {
  display: flex;
  gap: 5px;
  margin-left: 19px;
  width: 424px;
}
.to {
  display: flex;
  gap: 5px;
  margin-left: 27px;
}

.pay_no {
  padding-left: 40px;
}

.pay_acc_code {
  padding-left: 55px;
}

.header__payments {
  display: flex;
}
.payments_narration_view {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  padding: 20px;
  font-size: 16px;
  margin-left: 200px;
}
.payments_view_docno {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  padding: 20px;
  font-size: 16px;
  margin-left: 200px;
}
.value_payments_docno {
  padding-left: 65px;
}
.value_payments_date {
  text-align: center;
  margin: 2px 2px;
  padding: 20px 74px 20px 0px;
}
.value_payments_vendor {
  text-align: left;
}
.value_payments_amount {
  text-align: right;
  margin: 2px 2px;
  padding-right: 37px;
  width: 150px;
}
.value_payments_curr {
  text-align: center;
  margin: 4px 4px;
  width: 128px;
}
.header_payments_date {
  padding-right: 92px;
}
.header_payments_vendor {
  text-align: left;
}
.header_payments_amount {
  text-align: left;
  padding-left: 18px;
}
.header_payments_curr {
  text-align: left;
}
.total_payments_cr_dr_amount {
  padding-right: 113px;
}
.style__width {
  width: 800px;
}
.closed_c {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 600px) {
  .style__width {
    width: 300px;
  }
  .Reload {
    margin-left: 2px;
  }

  .Filter {
    display: block;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 10px;
    gap: 40px;
  }
  .Payment_data_header {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 500;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 37px;
    font-size: smaller;
  }
  .pay_no {
    padding-left: 14px;
  }
  .pay_acc_code {
    padding-left: 16px;
  }
  .from_unp {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
  }

  .Doc_No {
    display: block;
    gap: 5px;
    margin-left: 10px;
  }
  .Doc_No > div > input {
    margin: 10px 0px 10px;
  }
  .Vendor {
    display: block;
    margin-left: 10px;
    gap: 5px;
  }
  .Vendor > div > input {
    margin: 10px 0px 10px;
  }
  .Payment_tr th:nth-child(5),
  .Payment_tr td:nth-child(5) {
    text-align: left;
    padding-left: 30px;
  }
  .Vend_unp {
    display: block;
    margin-top: 17px;
    margin-left: 0px;
  }
  .from {
    display: block;
    gap: 5px;
    margin-top: 0px;
    margin-left: 10px;
    width: 0px;
  }
  .from > div > input {
    margin: 10px 0px 10px;
  }
  .to {
    display: block;
    margin-left: 10px;
  }
  .to > div > input {
    margin: 10px 0px 10px;
  }
  .Titles {
    margin-top: 17px;
    margin-left: 5px;
    color: white;
    font-size: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .Approve {
    display: flex;
    margin-left: 0px;
    align-items: center;
    margin-top: 10px;
  }
  .from_unp_date {
    display: flex;
    flex-direction: column;
    margin-top: 1px;
    margin-left: 0px;
  }
  .Payment_tr th:nth-child(2) {
    padding-left: 0px;
  }
  .Payment_tr_view {
    font-size: smaller;
  }
  .Payment_tr_view th:nth-child(2) {
    padding-right: 0px;
    width: 0px;
  }
  .po_item_descrip {
    width: 400px;
    padding-left: 21px;
  }
  .Payment_tr th:nth-child(3) {
    padding-left: 2px;
  }
  .po_item_amount_1 {
    padding-left: 50px;
  }

  .value_payments_docno {
    padding-left: 19px;
  }
  .value_payments_amount {
    margin: 2px 2px;
    padding-left: 23px;
  }
  .payments_view_docno {
    display: block;
    padding: 20px;
    font-size: small;
    margin-left: 0px;
  }
  .payments_narration_view {
    display: block;

    padding: 20px;
    font-size: small;

    margin-left: 0px;
    margin-top: -35px;
  }
  .view_header_cr_dr {
    padding-left: 45px;
  }
  .view_header_amount {
    padding-left: 45px;
  }
  .view_header_curr {
    padding-left: 0px;
  }
  .total_payments_cr_dr_amount {
    padding-right: 113px;
  }

  .view_header_conv_rate {
    width: 197px;
    padding-left: 20px;
  }

  .view_payments_cr_dr {
    text-align: right;
    padding-right: 19px;
  }

  .view_payments_amount {
    text-align: right;
    padding-right: 51px;
  }
  .view_payments_curr {
    padding-left: 30px;
  }

  .view_payments_conv_rate {
    text-align: right;
    padding-right: 13px;
  }
  .thead {
    background-color: #002d62;
    height: 50px;
    border: none;
    color: white;
    font-size: smaller;
    z-index: 100;
  }
  .Payments_tbody {
    background-color: white;
    text-align: left;
    font-size: smaller;
  }
  .View {
    height: 27px;
  }
}
