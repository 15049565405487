* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.login__img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Login_form {
  border-top: 2px solid gray;
  border-left: 1px solid gray;
  position: relative;
  width: 380px;
  padding: 50px 50px 50px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: white;
}

.Form {
  margin-top: -40px;
}
/* .UserDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
  font-family: Mulish, fira-sans-2, sans-serif;
} */
.inputBox {
  display: flex;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  padding-left: 40px;
  margin-bottom: 20px;
  border-color: lightgray;
  height: 35px;

  outline: none;
  border-width: 1px;
}

/* input {
  height: 25px;
  /* border: none; */
/* border-bottom: 1px solid grey; 
  width: 200px;
  margin-bottom: 15px;
  font-family: Mulish, fira-sans-2, sans-serif;
} */

.login {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-weight: 50;
  color: BoDBoA;
  font-family: Mulish, fira-sans-2, sans-serif;
}
.label_1 {
  margin-right: 160px;
  margin-bottom: 13px;
  font-size: 14px;
  font-family: Mulish, fira-sans-2, sans-serif;
}

.label_2 {
  margin-right: 136px;
  margin-bottom: 13px;
  font-size: 14px;
  font-family: Mulish, fira-sans-2, sans-serif;
}

.lgn_button {
  font-size: 16px;
  width: 283px;
  margin-top: 25px;
  border-radius: 5px;
  height: 36px;
  border: none;

  color: white;
  background: teal;
  cursor: pointer;
}
.pass {
  display: flex;
  margin-right: 100px;
  font-size: 25px;
  gap: 5px;
  font-family: Mulish, fira-sans-2, sans-serif;
}
.Hi {
  display: flex;
  margin-right: 100px;
  font-size: 25px;
  gap: 5px;
  font-family: Mulish, fira-sans-2, sans-serif;
}
.Check {
  display: flex;
  flex: wrap;
  gap: 2px;
  font-family: Mulish, fira-sans-2, sans-serif;
}
.show {
  margin-right: 1px;
}

input:focus {
  outline: none;
}

.wrapper {
  position: relative;
  margin-bottom: 11px;
  display: flex;
}

.icon {
  position: absolute;

  margin-top: 10px;
  margin-left: 10px;
}

/* .input {
  height: 20px;
  box-sizing: border-box;
  padding-left: 1.5rem;
} */
.fgpass {
  color: #7579ff;
  font-size: 14px;
  margin-right: 18px;
  margin-top: -7px;
}

#sp {
  margin-left: 185px;
}
.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 4px;
  text-align: center;
  margin-bottom: 30px;
}
.offscreen {
  display: none;
}

.eye {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 10px;
  margin-top: 10px;
}
.lgn_button {
  display: block;
}
@media only screen and (max-width: 920px) {
  .container {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .login__img {
    font-size: 30px;
    width: 100%;
  }
  .login__img > img {
    width: 95%;
    height: auto;
  }
  .Login_form {
    padding: 30px;
    width: 55%;
  }
}
@media only screen and (max-width: 420px) {
  .container {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .login__img {
    font-size: 24px;
    width: 100%;
  }
  .login__img > img {
    width: 95%;
    height: auto;
  }
  .Login_form {
    padding: 30px 10px;
    width: 95%;
  }
}
