.Select_pay_mode {
  width: 250px;
}
.lbl_pay_mode {
  margin-left: 70px;
}
.Select_pay_mode_2 {
  width: 200px;
  margin-right: 82px;
}
.Select_Sub_Category_1 {
  width: 350px;
}
.clearbutton__allPage {
  display: flex;
  justify-content: end;
  padding: 0px 10px;
}
.clearbutton__allPage > button {
  padding: 5px 10px;
  background: #0a1835;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
@media only screen and (max-width: 660px) {
  .lbl_pay_mode {
    margin-left: 0px;
  }
}
