.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: #f9f9f9;
}

.back-button-container {
  position: absolute;
  top: 20px;
  left: 20px;
}

.not-found-content {
  text-align: center;
}

.not-found-content h1 {
  font-size: 5rem;
  color: #001227;
  margin: 0;
}

.not-found-content p {
  font-size: 1.5rem;
  color: #555;
}
