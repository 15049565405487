.checkbox {
  width: 15px;
  height: 15px;
  background-color: white;
  margin-left: 25px;
}
.button {
  background-color: #002d62;
  border: 1px solid transparent;
  z-index: 0;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15385;
  height: 35px;

  margin: 10px;
  margin-left: 10px;
  outline: none;
  padding: 5px 0.8em;
  position: relative;
  text-align: center;
}
.button_1 {
  display: flex;
  gap: 20px;
}

input[type="checkbox"] {
  accent-color: #002d62;
}
.Payment_tr_view > th {
  font-weight: 500;
  width: 200px;
  /* text-align: left; */
}

.Payment_tr_view th:nth-child(2) {
  padding-right: 100px;
  /* width: 172px; */
}
.po_item_code_1 {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
}
.po_item_descrip {
  width: 400px;
  padding-left: 70px;
}
.po_item_amount_1 {
  text-align: right;
  padding-right: 45px;
}
.or_supp_name {
  margin: 2px 2px;
  padding: 0px 14px 0px 33px;
  width: 350px;
}
.or_supp_code {
  text-align: right;
  margin: 2px 2px;

  width: 150px;
}

.sec_line {
  display: flex;
  margin-top: 20px;
  /* gap: 260px; */
}
.View_1 {
  background-color: white;

  border: 1px solid transparent;

  border-radius: 3px;

  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;

  box-sizing: border-box;

  color: black;

  cursor: pointer;

  display: inline-block;

  font-size: 20px;

  font-weight: 400;

  line-height: 1.15385;
  height: 40px;
  width: 75px;

  margin: 0;

  outline: none;

  padding: 8px 0.8em;

  position: relative;

  text-align: center;

  text-decoration: none;

  user-select: none;

  -webkit-user-select: none;

  touch-action: manipulation;

  vertical-align: baseline;

  white-space: nowrap;
  width: 110px;
  margin-left: 625px;
  margin-top: 12px;
}
.View_1:active {
  background-color: #0064bd;

  box-shadow: none;
}
.first_line {
  display: flex;
  margin-top: 20px;

  padding: 10px;
  background-color: aliceblue;
  border-radius: 10px;
}
.Sup_Name {
  display: flex;
  margin-left: 35px;
  gap: 5px;
}
.value_purchase_pono {
  text-align: right;
  padding-right: 75px;
}
.Sup_code {
  display: flex;
  gap: 5px;
  margin-left: 45px;
}
.Po_No {
  display: flex;
  gap: 5px;
  margin-left: 65px;
}
.from_Date {
  display: flex;
  margin-left: 34px;
  gap: 5px;
  /* width: 470px; */
}
.to_Date {
  display: flex;
  gap: 5px;
  margin-left: 253px;
}
.fr_dt {
}
.input_1 {
  height: 30px;
}
.btns {
  margin-top: -12px;
  margin-left: 161px;
}
.Refresh {
  background-color: #002d62;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15385;
  height: 35px;
  width: 110px;
  outline: none;
  padding: 5px 0.8em;
  position: relative;
  text-align: center;
  margin: 10px;
}
.from_unp_date {
  display: flex;

  background-color: aliceblue;
  padding: 10px;
  border-radius: 8px;
}

.po_no {
  padding-left: 30px;
}
.po_item_code {
  padding-left: 55px;
}
.po_item_desc {
  text-align: center;
  margin: 2px 2px;
  padding: 20px 15px 20px 0px;
  width: 200px;
}
.po_item_amount {
  text-align: right;
  margin: 2px 2px;
  padding: 20px 15px 20px 0px;
}
.po_mode_pay {
  text-align: left;
  width: 270px;
  padding-left: 87px;
}
.supplier_name {
  text-align: left;
  width: 280px;
}
.header_po_mode_pay {
  text-align: left;
  padding-left: 80px;
}
.purchase__orders {
  display: flex;

  gap: 250px;
  padding: 20px;
  font-size: 16px;
}
.supp_addr {
  display: flex;

  font-size: 16px;
  gap: 315px;
  margin-right: 29px;
}
.PO_CURREN {
  text-align: center;
}
.po__pay {
  text-align: center;
}

.button_2 {
  background-color: #002d62;

  border: 1px solid transparent;
  z-index: 0;

  border-radius: 3px;

  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;

  box-sizing: border-box;

  color: #fff;

  cursor: pointer;

  display: inline-block;

  font-size: 20px;

  font-weight: 400;

  line-height: 1.15385;
  height: 40px;
  /* width: 75px; */

  margin: 0;

  outline: none;

  padding: 8px 0.8em;

  position: relative;

  text-align: center;

  text-decoration: none;

  user-select: none;

  -webkit-user-select: none;

  touch-action: manipulation;

  vertical-align: baseline;

  white-space: nowrap;
}
.header_purchase_pono {
  padding-right: 40px;
  width: 100px;
}
.header_purchase_po_date {
  padding-right: 44px;
}
.header_purchase_supp_code {
  text-align: left;
}
.header_purchase_supp_name {
  text-align: left;
}
.header_purchase_days {
  padding-right: 25px;
}
.header_purchase_created_by {
  text-align: left;
}
.header_purchase_action {
  width: 100px;
}
.or_value_days {
  padding-left: 20px;
}
.Narration_1 {
  max-width: 281px;
  min-width: 281px;
  max-height: 100px;
  min-height: 100px;
  padding: 10px;
}
@media only screen and (max-width: 600px) {
  .scroll {
    overflow: auto;
    width: 100%;
  }
  .header_purchase_po_date {
    padding-right: 0px;
  }
  .purchase__orders {
    display: block;
    justify-content: center;
    gap: 40px;
    padding: 8px;
    font-size: small;
  }
  .supp_addr {
    display: block;

    font-size: small;

    margin-left: -10px;
  }
  .header_purchase_supp_code {
    text-align: left;
    padding-left: 9px;
  }
  .View {
    height: 27px;
  }
  .value_purchase_pono {
    text-align: left;
    padding-left: 49px;
  }
  .first_line {
    display: block;
    margin-top: 20px;
    gap: 50px;
    background-color: aliceblue;
    padding: 10px;
    margin-left: 0px;
  }
  .po_mode_pay {
    text-align: left;
    /* width: 551px; */
    padding-left: 50px;
  }
  .supplier_name {
    text-align: right;
    width: 280px;
    padding-right: 0px;
  }
  .Sup_Name {
    display: block;
    margin-left: 10px;
    gap: 0px;
  }
  .Sup_Name > div > input {
    margin: 10px 0px 10px;
  }
  .Sup_code {
    display: block;
    margin-left: 10px;
  }
  .Sup_code > div > input {
    margin: 10px 0px 10px;
  }
  .from_unp_date {
    display: block;
    margin-left: 0px;
    margin-top: 0px;
  }
  .po_no {
    padding-left: 5px;
  }
  .Po_No {
    display: block;
    margin-left: 10px;
  }
  .Po_No > div > input {
    margin: 10px 0px 10px;
  }
  .po_item_code {
    padding-left: 16px;
  }
  .po_item_amount {
    text-align: right;
    margin: 2px 2px;
    padding: 20px 5px 20px 0px;
  }

  .from_Date {
    display: block;
    margin-left: 10px;
    gap: 0px;
    width: 207px;
  }
  .from_Date > div > input {
    margin: 10px 0px 10px;
  }

  .Refresh {
    margin: 10px;
  }

  .View_1 {
    margin-left: 0px;
    margin-top: -10px;
  }
  .button_2 {
    margin-top: 10px;
  }
  .po_item_code_1 {
    padding-left: 56px;
  }
  .Payment_tr_view th:nth-child(2) {
    /* padding-right: 240px; */
    /* width: 172px; */
  }
  .header_po_mode_of_pay {
    padding-right: 11px;
  }
  /* .Narration_1 {
    width: 269px;
    height: 80px;
  } */

  .Narration_1 {
    max-width: 269px;
    min-width: 269px;
    max-height: 80px;
    min-height: 80px;
  }
  .to_Date {
    display: block;
    gap: 5px;
    width: 189px;
    margin-left: 10px;
  }
  .to_Date > div > input {
    margin: 10px 0px 10px;
  }
  .btns {
    margin-top: 0px;
    margin-left: 0px;
  }
}
